import React, { useEffect } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { message } from "antd";
import { observer } from "mobx-react-lite";
import { createTheme, ThemeProvider as MaterialUIThemeProvider } from "@mui/material";
import HeadlessEditor from "./pages/Editor/HeadlessEditor";
import "./App.css";
import GlobalStyles, { lightTheme, darkTheme } from "./globalStyles";
import { appStore } from "./stores/appStore";
import { wizardStore } from "./stores/wizardStore";
import { useGeneratedBase64Data } from "./hooks/useGeneratedBase64Data";
import { QRCodeMessagePage } from "./pages/QRCodeMessage/QRCodeMessagePage";
import { DesignV2 } from "./pages/v2/Design/DesignV2";
import { EditorV2 } from "./components/v2/EditorSharedComponents/Editor";
import { CartV2 } from "./pages/v2/Cart/Cart";
import { NotificationDisplay } from "./components/v2/OtherComponents/Notification";

const size = {
  xs: "400px", // for small screen mobile
  sm: "576px", // for mobile screen
  md: "768px", // for tablets
  lg: "992px", // for laptops
  xl: "1200px", // for desktop / monitors
  xxl: "1920px", // for big screens
};

export const generalTheme = {
  color: {
    gray: "#EFEFEF", // "#4287f5"
    darkGray: "#8C8C8C",
    noticeText: " #787878", // TODO maybe convert all to use instead of color naming, but I should talk to Juan before doing that
    black: "#000000",
    white: "#FFFFFF",
    alertRed: "#FF0000",
    notSelectedInputTextColor: "#858585", // might not be needed check with Juan
  },
  fontSize: {
    input: "16px",
  },
  devicesMax: {
    xs: `(max-width: ${size.xs})`,
    sm: `(max-width: ${size.sm})`,
    md: `(max-width: ${size.md})`,
    lg: `(max-width: ${size.lg})`,
    xl: `(max-width: ${size.xl})`,
    xxl: `(max-width: ${size.xxl})`,
  },
  devicesMin: {
    xs: `(min-width: ${size.xs})`,
    sm: `(min-width: ${size.sm})`,
    md: `(min-width: ${size.md})`,
    lg: `(min-width: ${size.lg})`,
    xl: `(min-width: ${size.xl})`,
    xxl: `(min-width: ${size.xxl})`,
  },
  defaultBorderRadius: "5px",
  largeBorderRadius: "15px",
  navBarHeight: 10, // in rem
  navBarHeightMobile: 6, // in rem
  POSTER_WIDTH_RAW_V2: 400,
  ALBUM_POSTER_WRAPPER_WIDTH_PERCENTAGE: 70
};

const { palette } = createTheme();
const { augmentColor } = palette;
const createColor = (mainColor) => augmentColor({ color: { main: mainColor } });

const muiTheme = createTheme({
  palette: {
    dark: createColor(generalTheme.color.black),
    gray: createColor(generalTheme.color.gray),
  },
  typography: {
    fontFamily: "\"Plus Jakarta Sans\", sans-serif",
  }
});

const App = observer(() => {
  const {
    currentPoster,
    spotifyApi,
    populateHeadlessPosterConfigs,
    logAnalyticsEvent,
    enableHeadlessMode,
    disableSingleTrackPoster,
  } = appStore;

  const {
    theme
  } = currentPoster;

  const {
    updateSpotifyInstance
  } = wizardStore;

  useGeneratedBase64Data(appStore);

  // Process headless mode params when spotifyApi is ready
  useEffect(async () => {
    if (!spotifyApi) return;
    updateSpotifyInstance(spotifyApi);

    if (window.location.pathname !== "/headless") return;
    enableHeadlessMode();
    await populateHeadlessPosterConfigs(spotifyApi);
  }, [spotifyApi]);

  useEffect(() => {
    if (currentPoster.error) {
      message.error({
        content:
          `Sorry, we are not able to generate the poster, please try again later or select other album.${typeof currentPoster.error === "string" && `Reason: ${currentPoster.error}`}`,
        style: {
          fontSize: "5rem",
        },
      });
      logAnalyticsEvent("exception", {
        description: "appError",
        fatal: false,
      });
    }
  }, [currentPoster.error]);

  useEffect(() => {
    appStore.initialize();
  }, []);

  return (
    <Switch>
      <ThemeProvider theme={theme === "light" ? { ...lightTheme, ...generalTheme } : { ...darkTheme, ...generalTheme }}>
        <NotificationDisplay />
        <MaterialUIThemeProvider theme={muiTheme}>
          <GlobalStyles />
          <Switch>
            {
              !disableSingleTrackPoster && (
                <Route
                  path="/design"
                  render={() => (
                    <DesignV2 />
                  )}
                />
              )
            }
            <Route path="/message" render={() => (<QRCodeMessagePage />)} />
            <Route
              path="/editor/multi"
              render={() => (
                <EditorV2 singleTrackEditor={false} />
              )}
            />
            {
              !disableSingleTrackPoster && (
                <Route
                  path="/editor/single"
                  render={() => (
                    <EditorV2 singleTrackEditor />
                  )}
                />
              )
            }
            <Route
              path="/cart"
              render={() => (
                <CartV2 />
              )}
            />
            <Route path="/headless" render={() => <HeadlessEditor />} />
            <Redirect to={disableSingleTrackPoster ? "/editor/multi" : "/design"} />
          </Switch>
        </MaterialUIThemeProvider>
      </ThemeProvider>
    </Switch>
  );
});

export default App;
