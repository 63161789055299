import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import styled from "styled-components";

import { ANALYTICS_MULTI_TRACK_LABEL, appStore } from "../../../../../stores/appStore";
import { wizardStore } from "../../../../../stores/wizardStore";
import { generateTestId } from "../../../../../util_code/testUtil";
import { Label } from "../../../../../components/v2/Text/Label";
import { StyledSpotifySearchInput, StyledSpotifySearchResults } from "../../../../../components/v2/EditorSharedComponents/SearchSharedStyles";
import { SpotifySearchResultV2 } from "../../../../../components/v2/EditorSharedComponents/SpotifySearchResult";
import { useIsMobile } from "../../../../../hooks/isMobile";

export const SearchWizardV2 = observer(() => {
  const [openSearch, setOpenSearch] = useState(true);
  const isMobile = useIsMobile();

  const {
    currentPoster,
    spotifyApi,
    logAnalyticsEvent
  } = appStore;

  const {
    search,
    setSearch,
    artistAlbumResults,
  } = wizardStore;

  const {
    album: selectedAlbum,
    setLoading,
    fetchAudioAnalysisMultiTrack,
    fetchDescriptionAsync,
    setAlbum,
    setAlbumTitle,
    setAlbumId,
    setSpotifyUrl
  } = currentPoster;

  const onSearchChange = (event) => {
    setSearch(event.target.value);
  };

  const chooseAlbum = async (albumOption) => {
    setOpenSearch(false);
    setLoading(true);
    setAlbum(albumOption);
    setAlbumTitle(albumOption.title);
    setAlbumId(albumOption.id);
    const spotifyAlbumUrl = albumOption.id ? `https://open.spotify.com/album/${albumOption.id}` : "";
    setSpotifyUrl(spotifyAlbumUrl);

    await fetchAudioAnalysisMultiTrack();
    await fetchDescriptionAsync();

    logAnalyticsEvent(ANALYTICS_MULTI_TRACK_LABEL, {
      category: "interaction",
      action: "selected_album_name",
      label: albumOption.title
    });

    logAnalyticsEvent(ANALYTICS_MULTI_TRACK_LABEL, {
      category: "interaction",
      action: "selected_album_artist",
      label: albumOption.artist
    });
  };

  return (
    <SearchWizardContainer>
      <StyledLabel data-explorator_test_id={generateTestId("multi-track-wizard", "step-1-title")}>Album Or Artist</StyledLabel>
      <SearchWrapper>
        {
          JSON.stringify(selectedAlbum) !== "{}" && !openSearch ? (
            <SpotifySearchResultV2 result={selectedAlbum} key="n/a" openSearch={() => setOpenSearch(true)} />
          ) : (
            <>
              <StyledSpotifySearchInput
                value={search}
                onChange={onSearchChange}
                name="searchValue"
                className="spotify-search"
                type="search"
                data-explorator_test_id={generateTestId("multi-track-wizard", "spotify-search-input")}
                placeholder={spotifyApi ? "Search" : "Loading..."}
                disabled={!spotifyApi}
              />
              <StyledSpotifySearchResults hasResult={artistAlbumResults.length > 0}>
                {artistAlbumResults.slice(0, 3).map((album) => (
                  <SpotifySearchResultV2 result={{ ...album, image: album.albumUrl }} key={album.id} onSelectResult={(selectedResult) => chooseAlbum(selectedResult)} />
                ))}
              </StyledSpotifySearchResults>
              {isMobile ? <div style={{ height: "5rem" }} /> : <div style={{ height: "15rem" }} />}
            </>
          )
        }
      </SearchWrapper>

    </SearchWizardContainer>
  );
});

const SearchWizardContainer = styled.div`
  height: 40vh;
  @media ${({ theme }) => theme.devicesMax.md} { 
    height: fit-content;
  }
`;

const StyledLabel = styled(Label)`

`;

export const StepOneTextIntro = styled.p`
  font-size: 2rem;
  text-align: center;
  margin-top: 1.8rem;
  letter-spacing: 0.09rem;

  & > span {
    font-weight: 600;
    font-size: 1.8rem;
  }

  @media ${({ theme }) => theme.devicesMax.md} {
    font-size: 1.7rem;
  }
`;

// TODO make re-usable with single track
export const SearchWrapper = styled.div`
  margin: 0;
  width: 100%;

  @media ${({ theme }) => theme.devicesMax.md} {
    margin: 0;
    width: 100%;
  }
`;

export const AlbumResultsWrapper = styled.div`
  max-height: 30vh;
  overflow-y: auto;
  width: 100%;
  margin: 0 auto;
  margin-top: 0.5rem;
  border-radius: ${({ theme }) => theme.defaultBorderRadius};
  background-color: ${({ theme }) => theme.color.gray};

  @media ${({ theme }) => theme.devicesMax.sm} {
    margin: 0 auto;
    width: 90%;
  };

  &.active{
    background-color: #FFFFFF;
  }
`;

export const AlbumResultsContainer = styled.div`
  padding: .8rem 1rem;
  display: flex;
  justify-content: flex-start;
  gap: 1rem;
  align-items: center;
  border-bottom: 1px solid #ccc;
  cursor: pointer;


  ${({ selectedAlbum }) => selectedAlbum
    && `
    background-color: #fff;
    border: 1px solid green;
    margin-bottom: 3rem;
  `};
`;

export const AlbumImage = styled.div`
  height: 5rem;
  width: 5rem;
  border-radius: 50%;
  border: 1px solid #ccc;
  background: #777;
  background-image: url(${props => props.img});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`;

export const AlbumImageText = styled.div`
  font-size: 2.5rem;
  letter-spacing: 0.05rem;
  max-width: 80%;

  @media ${({ theme }) => theme.devicesMax.sm} {
    max-width: 70%;
  }

  & > p {
    margin: 0;
  }

  & > p:nth-child(1) {
    font-weight: 600;
  }

  & > p:nth-child(2) {
    font-weight: normal;
    color: #777;
  }
`;
