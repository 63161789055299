import React, { useCallback, useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import { useDebouncedCallback } from "use-debounce";
import styled from "styled-components";
import { ANALYTICS_SINGLE_TRACK_LABEL, appStore } from "../../../../../stores/appStore";
import { generateTestId } from "../../../../../util_code/testUtil";
import { SongTitleAndArtistFontSizeSelectorV2 } from "./SongTitleAndArtistFontSizeSelector";
import { SpotifySearchResultV2 } from "../../../../../components/v2/EditorSharedComponents/SpotifySearchResult";
import { StyledSpotifySearchInput, StyledSpotifySearchResults } from "../../../../../components/v2/EditorSharedComponents/SearchSharedStyles";
import { Label } from "../../../../../components/v2/Text/Label";

export const SingleSearchWizardV2 = observer(() => {
  const { currentPoster, spotifyApi, logAnalyticsEvent } = appStore;

  const {
    setLoading,
    fetchAudioAnalysisAsyncSingleTrack,
    setAlbum,
    setAlbumId,
    setSong,
    setTrackId,
    setSongName,
    setArtist,
    setIsSingleTrackPoster,
    setSpotifyUrl,
    song: selectedSong,
    clearSong,
  } = currentPoster;

  const [keywords, setKeywords] = useState("");
  const [songResults, setSongResults] = useState([]);
  const [openSearch, setOpenSearch] = useState(true);

  const handleChange = useCallback((e) => {
    setKeywords(e.target.value);
  }, []);

  useEffect(
    useDebouncedCallback(async () => {
      if (!keywords) return;
      const searchResults = await spotifyApi.searchTracks(keywords, {
        limit: 10,
      });

      setSongResults(searchResults.body.tracks.items);
    }, 300),
    [keywords]
  );

  const artistNames = useMemo(() => {
    if (!selectedSong || !selectedSong.artists) return "";

    const artistsText = selectedSong.artists.reduce(
      (names, artist) => names.concat(artist.name),
      []
    );
    return artistsText && artistsText.join(",");
  }, [selectedSong]);

  const isSongSelected = !!selectedSong;

  const chooseSong = async (chosenSong) => {
    setOpenSearch(false);
    const albumOption = chosenSong.album;
    setLoading(true);

    setAlbum(albumOption);
    setAlbumId(albumOption.id);
    setSong(chosenSong);
    setTrackId(chosenSong.id);
    setIsSingleTrackPoster(true);
    setSongName(chosenSong.name);
    setArtist(chosenSong.artists[0].name);
    setSpotifyUrl(chosenSong.external_urls && chosenSong.external_urls.spotify ? chosenSong.external_urls.spotify : "");
    await fetchAudioAnalysisAsyncSingleTrack();

    logAnalyticsEvent(ANALYTICS_SINGLE_TRACK_LABEL, {
      category: "interaction",
      action: "selected_album_name",
      label: albumOption.title,
    });

    logAnalyticsEvent(ANALYTICS_SINGLE_TRACK_LABEL, {
      category: "interaction",
      action: "selected_album_artist",
      label: albumOption.artist,
    });
  };

  const getImageURLFromSong = (songToGetURLFrom) => {
    if (songToGetURLFrom && songToGetURLFrom.album && songToGetURLFrom.album.images && Array.isArray(songToGetURLFrom.album.images) && songToGetURLFrom.album.images.length > 0) return songToGetURLFrom.album.images[0].url;
  };

  return (
    <>
      <Label>Album Or Artist</Label>
      <SearchWrapper>
        {isSongSelected && !openSearch ? (
          <SpotifySearchResultV2 result={{ ...selectedSong, image: getImageURLFromSong(selectedSong), title: selectedSong.name, artist: artistNames }} openSearch={() => setOpenSearch(true)} />
        ) : (
          <>
            <StyledSpotifySearchInput
              value={keywords}
              onChange={handleChange}
              className="spotify-search"
              type="search"
              placeholder={spotifyApi ? "Search Song Name" : "Loading..."}
              disabled={!spotifyApi}
              data-explorator_test_id={generateTestId("single-track", "wizard-spotify-search-input")}
            />
            <StyledSpotifySearchResults hasResult={songResults.length > 0}>
              {songResults.map((song) => (
                <SpotifySearchResultV2 isSingleTrackPoster result={{ ...song, image: getImageURLFromSong(song), title: song.name, artist: song.artists.reduce((names, artist) => names.concat(artist.name), []).join(",") }} key={song.id} onSelectResult={chooseSong} />
              ))}
            </StyledSpotifySearchResults>
          </>
        )}
        <SongTitleAndArtistFontSizeSelectorV2 />
      </SearchWrapper>
    </>
  );
});

const SearchWrapper = styled.div`
`;
